import Vue from 'vue'

function findValuesByChain (state, chain) {
  let result = state.options
  if (state.options && chain) {
    const categories = chain.split('.')
    let categoryFound = state.options
    categories.forEach(category => {
      if (categoryFound.children) {
        categoryFound = categoryFound.children
      }
      categoryFound = categoryFound.find(option => option.name === category)
    })
    result = categoryFound ? categoryFound.serviceValues : null
  }
  return result
}

export default {
  namespaced: true,
  state: {
    options: null,
    userRoles: null
  },
  mutations: {
    setOptions (state, { categoryChain, data }) {
      if (data.userRoles) {
        state.userRoles = data.userRoles
      }
      if (data.serviceOptions) {
        data = data.serviceOptions
      }
      if (categoryChain) {
        let values = findValuesByChain(state, categoryChain)
        if (values) {
          values = data
        }
      } else {
        state.options = data
      }
    },
    updateOption (state, { categoryChain, data }) {
      let values = findValuesByChain(state, categoryChain)
      if (values) {
        const valueFoundIndex = values.findIndex(value => value.id === data.id)
        if (valueFoundIndex > -1) {
          values[valueFoundIndex] = data
        } else {
          values.push(data)
        }
      }
    }
  },
  actions: {
    async optionValues ({ commit, dispatch }) {
      let serverResponse = false
      try {
        serverResponse = await Vue.axios.get('/service-data/service-options')
        if (serverResponse.data.success) {
          commit('setOptions', { categoryChain: null, data: serverResponse.data.data })
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        dispatch('systemMessages/handleError', e, { root: true })
      }
      return serverResponse
    },
    async save ({ dispatch }, { data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('/service-data/service-options', { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
          dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async delete ({ dispatch }, { id }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.delete('/service-data/service-options?id=' + id)
        if (serverResponse.data.success) {
          response = serverResponse.data.data
          dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    }
  },
  getters: {
    optionValue: (state, getters) => (categoryChain, optionId) => {
      let optionValue = null
      if (state.options && optionId) {
        const category = getters['optionsList'](categoryChain)
        if (category) {
          optionValue = category.find(value => value.id === optionId)
          if (optionValue) {
            optionValue = optionValue.value
          }
        }
      }
      return optionValue
    },
    optionsList: (state) => (categoryChain = null, withEmpty = true, emptyAll = false) => {
      let result = state.options
      if (state.options && categoryChain) {
        const categories = categoryChain.split('.')
        let categoryFound = state.options
        categories.forEach(category => {
          if (categoryFound.children) {
            categoryFound = categoryFound.children
          }
          categoryFound = categoryFound.find(option => option.name === category)
        })
        result = categoryFound ? categoryFound.serviceValues : null
        if (result) {
          if (emptyAll) {
            result = [{ id: null, value: 'Все' }].concat(result)
          } else if (withEmpty) {
            result = [{ id: null, value: 'Не указано' }].concat(result)
          }
        }
      }
      return result
    },
    roles: (state) => {
      let roles = []
      if (state.userRoles) {
        roles = state.userRoles.roles
      }
      return roles
    },
    roleByName: (state) => (name) => {
      let role = null
      if (state.userRoles) {
        role = state.userRoles.roles.find(role => role.name === name)
      }
      return role
    },
    customPermissions: (state) => {
      let permissions = []
      if (state.userRoles) {
        permissions = state.userRoles.customPermissions
      }
      return permissions
    },
    isLoading: (state) => !state.options
  }
}
